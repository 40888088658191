// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgTrophy = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.983 2.25c.945-.021 1.588.301 1.996.704a2.1 2.1 0 0 1 .486.74l.012.031c.394.875.584 2.766-1.04 4.453a32 32 0 0 1-3.505 3.122c-.38 1.521-.887 3.051-1.566 4.255-.357.632-.794 1.228-1.324 1.674l.094.048c.477.248.996.578 1.406 1.003.412.427.76 1.004.76 1.72a.75.75 0 0 1-.75.75H8.507a.75.75 0 0 1-.75-.75c0-.716.348-1.293.76-1.72.41-.425.929-.755 1.405-1.003l.094-.048c-.504-.425-.924-.985-1.27-1.582-.707-1.218-1.23-2.789-1.62-4.345a31 31 0 0 1-3.563-3.124C1.937 6.49 2.13 4.598 2.524 3.723a1.5 1.5 0 0 1 .1-.228c.08-.152.206-.345.4-.535.405-.397 1.043-.717 1.982-.71.651.005 1.258.392 1.606.649.172.126.32.25.431.351h9.966c.107-.098.246-.218.407-.34.336-.256.925-.645 1.567-.66m-6.946 14.257c.673-.004 1.357-.511 2.022-1.69.62-1.097 1.106-2.58 1.48-4.132l.001-.007.052-.22c.377-1.621.62-3.25.77-4.477.06-.482.103-.901.135-1.231H7.562c.033.35.08.798.145 1.316.157 1.275.414 2.959.812 4.616.383 1.593.885 3.11 1.524 4.212.654 1.126 1.321 1.61 1.978 1.613zM6.218 6.25c.097.787.232 1.733.415 2.74a28 28 0 0 1-1.99-1.853c-1.161-1.206-.922-2.444-.745-2.811a1 1 0 0 0 .041-.105l.015-.032a.6.6 0 0 1 .12-.157c.108-.107.359-.286.92-.282.134.001.4.115.728.356.114.084.216.169.298.24l.027.317c.035.385.09.932.171 1.587m11.821-1.91-.025.297a49.3 49.3 0 0 1-.582 4.32 31 31 0 0 0 1.925-1.82c1.161-1.206.922-2.444.745-2.811a1 1 0 0 1-.043-.11l-.016-.035a.6.6 0 0 0-.12-.16c-.106-.106-.35-.284-.906-.271-.124.003-.378.114-.694.354a4 4 0 0 0-.284.237m-5.705 13.797a9 9 0 0 0-.305-.1 9 9 0 0 0-1.415.571c-.368.191-.7.408-.946.642h4.723c-.247-.234-.579-.45-.946-.642a8.5 8.5 0 0 0-1.11-.47"
      clipRule="evenodd"
    />
  </svg>
);
export const TrophyIcon = forwardRef(SvgTrophy);
